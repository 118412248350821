/**
 * @file
 * Styles for Cart
 */

@import "variables";

//
// Dropdown Cart
//

#block-menu-block-2 {
  padding: 10px 10px 12px 42px;
  width: 308px;
  @media only screen and (max-width: 767px) {
    padding: 0 10px 0;
    width: 100%;
    height: 41px;
  }
  &.active {
    background: #222;
  }
}

#block-commerce-cart-cart {
  display: none;
  position: absolute;
  right: 0;
  background: #222;
  color: white;
  z-index: 1;
  top: 0; //60px;
  padding: 0 10px 10px;
  padding-top: 40px;
  width: 308px;
  
  @media only screen and (max-width: 767px) {
    width: 100%;
    background: none;
    left: 0;
    padding: 0;
    top: 37px;
  }
  .inner {
    background: #222;
    width: 100%;
    @media only screen and (min-width: 495px) and (max-width: 767px) {
       width: 308px; 
       float: right;
    }
    @media only screen and (max-width: 767px) {
      padding: 10px;
      position: relative;
      top: 4px;
    }
  }
  a {
    color: $orange;
  }
  tbody, td { border-top: none; }
  tr {
    background: none;
    border-top: 1px solid $blue;
    border-bottom: 1px solid $blue-light;
    &.odd, &.even {
    }
  }
  .line-item-summary {
    margin-bottom: 0;
  }
  .view-footer {
    .line-item-total { margin-bottom: 5px; }
    ul.links {
      li {
        padding: 0;
        margin-top: 5px;
      }
    }
    a { // Checkout and View Cart buttons
      color: $orange;
      background: $blue-light;
      font-family: $secondary-typeface;
      opacity: .999;
      font-size: 1.2em;
      line-height: 1em;
      padding: 2px 5px;
      &:hover {
        background: $blue;
        text-decoration: none;
      }
    }
  }
}
.not-front #block-commerce-cart-cart {
   right: 10px;
   @media only screen and (min-width: 1900px) {
     right: 140px;
   }
}

// Front Page dropdown cart
body.front {
  #block-commerce-cart-cart {
    right: 90px;
    @media only screen and (max-width: 767px) {
      left: 0;
      //top: 37px;
    }
  }
}


//
// Cart Empty
//

.cart-empty-page {
  min-height: 80px;
  
  @media only screen and (min-width: 421px) and (max-width: 630px) {
    min-height: 120px;
  }
}
